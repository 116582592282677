<template>
  <div>
    <section v-for="(documentBlock, i) in documentsList" :key="i" class="documents-block">
      <article class="document-content">
        <header>
          <img :src="`${'/assets/icons/'+ iconType(documentBlock.type)}`" alt="icon document">
<!--          <img src="/assets/icons/research.svg" alt="icon document">-->
          <div style="margin: auto;"><h1>{{ documentBlock.name }}</h1>
            <p v-if="documentBlock.type !== 'link'">{{ documentBlock.type.toUpperCase() }}, {{ documentBlock.size }}</p>
          </div>
        </header>
        <div class="document-content__linc">
          <a :href="`${documentBlock.url}`">{{ buttonText(documentBlock.type) }}</a>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ForumDocuments',
  props: {
    documentsList: {
      type: Array,
      required: true,
      default: function () {
        return {
          name: null,
          url: null,
          size: null,
          type: null
        }
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    iconType (type) {
      return type !== 'link' ? 'document.svg' : 'research.svg'
    },
    buttonText (type) {
      return type !== 'link' ? 'Скачать' : 'Перейти'
    }
  }

  // mounted () {
  //   console.log('Запрос к сервису GetRegulatoryDocuments')
  //   this.$store.dispatch('getRegulatoryDocuments')
  // },
  // computed: {
  //   getMemberUnit () {
  //     return this.$store.getters.GET_DOCUMENT_LIST.filter(item => {
  //       return item.systemTagList === this.data
  //     })
  //   }
  // }
}
</script>

<style lang="scss" scoped>
  .documents-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.875rem;
    border: 1px solid #E5E8EE;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
    }
  }

  .document-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 1.875rem;
    font-style: normal;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.57;
    color: rgba(23, 23, 23, 0.8);
    width: 93%;

    & h1 {
      font-weight: 600;
      color: #1F3245;
    }
    & p {
      margin-bottom: 0;
    }

    header {
      display: flex;
      align-items: flex-start;

      img {
        width: 2.8125rem;
        height: auto;
        margin-right: 1.25rem;
      }
    }

    &__linc {
      a {
        margin: auto ;
        padding: .5rem 3.125rem;
        background: #246CB7;
        border-radius: .1875rem;
        color: #fff;
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.43;
        cursor: pointer;

        &:hover {
          background: #246cb780;
        }
      }
    }

  }

  @media screen and (max-width: 768px) {
    .documents-block{
      width: 100% !important;
    }
    .document-content {
      width: 94.5%;
      margin: 1rem;
      & header{
        margin-right: 1rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .document-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      header {
        margin: 0 0 1rem 0;
        width: 91%;
        div{
          margin: 0 !important;
        }

        img {
          width: 2.8125rem;
          height: auto;
          margin-right: 1rem;
        }
      }

      h1 {
        margin-bottom: .75rem;
      }

      .document-content__linc a{
        margin-left: 3.65rem;
      }
    }
  }

</style>
