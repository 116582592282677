<template>
  <div>
    <h2>
      <slot>Контактная информация</slot>
    </h2>
    <p v-if="false">По всем вопросам обращаться к организационному комитету</p>
    <section>
      <div>
        <p>Телефон</p>
        <a href="" class="telephone">{{ getItemForums.contacts_phone }}</a>
        <p>E-mail</p>
        <a href="" class="email" v-for="(item, i) in getItemForums.contacts_emails" :key="i">{{ item }}</a>
      </div>
      <div>
        <p>Социальные сети</p>
        <share-social :view_text="false"/>
      </div>
      <div>

      </div>
    </section>
  </div>

</template>

<script>
import ShareSocial from '@/components/activitiesSurveys/ForumSocialNetworks'

export default {
  name: 'ForumContacts',
  components: {
    ShareSocial
  },
  computed: {
    getItemForums () {
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
  }

  p {
    font-size: 0.875rem;
    margin-top: 1.25rem;
    line-height: 157%;
    color: rgba(23, 23, 23, 0.8);
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;

    a {
      color: #246CB7;
    }
  }

  ::v-deep .share-social {
    border-top: 0;
    margin-top: .75rem;
    padding: 0;
  }
</style>
