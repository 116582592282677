<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <section class="bg">
    </section>
    <div class="two-rows-wrapper">
      <main>
        <forum-stepper class="main-activity" id="nav_0">
          Форум «Сообщество»
        </forum-stepper>
        <div class="main-activity">
          <forum-about id="nav_1">O форуме</forum-about>
          <!-- <forum-news id="nav_2">Новости</forum-news> -->
          <forum-research id="nav_3" v-if="false">Исследование</forum-research>
          <!-- <share-social>Форум «Сообщество» в социальных сетях:</share-social> -->
        </div>
        <forum-partners class="main-activity" :partnersList="partners" id="nav_4">Партнеры</forum-partners>
        <forum-contacts  class="main-activity" id="nav_5">Контакты</forum-contacts>

      </main>
      <aside class="two-rows-navigation">
        <h2>Навигация по странице</h2>
        <nav>
          <a v-for="(nav, i, index) in anchorNavigation" :key="index" :href="`#nav_${index}`">{{ nav }}</a>
          <a href="http://www.xn--90aci0ajbadllemfl7f.xn--p1ai/" v-if="false">Архив до 2020 года</a>
          <!--          <router-link v-for="(nav, i, index) in anchorNavigation" :key="index" :to="{ path: '/', hash: `nav_${index}` }">{{ nav }}</router-link>-->
        </nav>
      </aside>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import ForumStepper from '@/components/activitiesSurveys/ForumStepper'
import ForumAbout from '@/components/activitiesSurveys/ForumAbout'
// import ForumNews from '@/components/activitiesSurveys/ForumNews'
// import ShareSocial from '@/components/activitiesSurveys/ForumSocialNetworks'
import ForumResearch from '@/components/activitiesSurveys/ForumResearch'
import ForumPartners from '@/components/activitiesSurveys/ForumPartners'
import ForumContacts from '@/components/activitiesSurveys/ForumContacts'

export default {
  name: 'Forum',
  components: {
    ForumResearch,
    BreadCrumb,
    ForumStepper,
    ForumAbout,
    // ForumNews,
    // ShareSocial,
    ForumPartners,
    ForumContacts
  },
  data () {
    return {
      anchorNavigation: {
        forums: 'Форумы в федеральных округах',
        aboutForum: 'О форуме',
        // news: 'Новости',
        // research: 'Каталог исследований',
        partners: 'Партнеры',
        contacts: 'Контакты'
      },
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Форум "Сообщество"'
        }
      ],
      partners: []
    }
  },
  beforeDestroy () {
    const script1 = document.querySelector('script[src*="salebot"]')
    const elements = document.getElementsByTagName('script')
    const frame = document.getElementById('parent_frame')
    let script2

    for (var i = 0; i < elements.length; i++) {
      if (elements[i]?.innerHTML?.includes('SaleBotPro')) {
        script2 = elements[i]
        break
      }
    }
    if (script1) {
      script1.remove()
    }
    if (script2) {
      script2.remove()
    }
    if (frame) {
      frame.remove()
    }
  },
  mounted () {
    this.$store.dispatch('getMainForumsList')
    document.title = 'Форум "Сообщество"'
    const script = document.createElement('script')
    script.src = 'https://salebot.pro/js/salebot.js?v=1'
    script.charset = 'utf-8'
    const scriptCode = document.createElement('script')
    scriptCode.innerHTML = 'SaleBotPro.init({ onlineChatId: "2927"});'
    document.head.appendChild(script)

    script.onload = () => {
      document.head.appendChild(scriptCode)
    }
  },

  computed: {
    getMainForums () {
      return this.$store.getters.GET_MAIN_FORUMS
    },
    getPartnersList () {
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    }
  },
  watch: {
    getMainForums () {
      const forums = JSON.parse(JSON.stringify(this.getMainForums)).sort((a, b) => b.year - a.year)
      this.$store.commit('SET_ITEM_MAIN_FORUM', forums.length ? forums[0] : [])
    },
    getPartnersList () {
      const partnersList = this.$store.getters.GET_MAIN_ITEM_FORUM.partners
      partnersList.forEach(item => {
        this.partners.push({
          title: item.link, img: item.url, url: item.link
        })
      })
    }
    // '$store.getters.GET_MAIN_ITEM_FORUM' () {
    //   console.log(this.$store.getters.GET_MAIN_ITEM_FORUM)
    //   // this.$store.dispatch('getMainForumsList', this.$store.getters.GET_MAIN_ITEM_FORUM[0].id)
    //   this.partners = this.$store.getters.GET_MAIN_ITEM_FORUM[0].partners
    // }
  }
}
</script>

<style lang="scss" scoped>
  section {
    width: 90rem;
    margin: 0 auto 3.125rem;
    height: 17rem;
    background: url('../../assets/bg/banner.png') center center no-repeat;
    background-size: cover, cover;
    border-radius: .5rem;
  }

  ::v-deep main {
    background: none;
    padding: 0;
    border-radius: 0;
  }

  .main-activity {
    font-size: 0.875rem;
    background: white;
    padding: 2.5rem 3.125rem;
    border-radius: 8px;
    margin-bottom: 3.125rem;

    figure {
      img {
        border-radius: 8px;
        max-width: 15rem;
        float: left;
        margin-right: 1.5625rem;
      }
    }

    article {
      text-align: left;
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: .2rem;

      p {
        margin: 0.35rem 0.625rem 0.35rem 0;
      }

      &__rubric {
        background: #EEF4FA;
        border-radius: 3px;
        font-weight: 600;
        font-size: 0.8125rem;
        line-height: 123%;
        padding: .375rem .625rem;
        text-transform: uppercase;
        color: #003E78;
        opacity: 0.8;
      }
    }
  }

  .comment-form {
    margin-top: 3.125rem;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: .8125rem;
  }

  ::v-deep #partners {
    a:first-child {
      margin-left: 0;
    }

    a:last-child {
      margin-right: 0;
    }

    a:nth-child(5n) {
      margin-right: 0;
    }

    a:nth-child(6n) {
      margin-left: 0;
      margin-right: .625rem;
    }
    a:nth-child(7n) {
      margin: .625rem;
    }
  }

  ::v-deep .partners_item {
    margin: .625rem;

  }

  @media screen and (max-width: 768px) {
    .main-activity {
      padding: 2.5rem 1rem;
    }
    .come-back {
      width: 90%;
    }
    section {
      width: auto;
    }
  }

  @media screen and (max-width: 420px) {
    .main-activity figure img {
      max-width: 100%;
      width: 100%;
      float: unset;
      margin-right: 0;
      margin-bottom: 2.125rem;
    }
  }

</style>
