<template>
  <div>
    <h1>
      <slot>Форум</slot>
      <custom-select key="inclusion" :title="thisYear" class="documents__filter-select" :list='getForumsYears' @InputsContent="setForumsYear"
                     placeholder="год"/>
    </h1>
    <tricolor-line class="margin-three-color"/>
    <!--    закоментил через v-if-->

    <h2 v-if="false">Прошедшие и будущие форумы в федеральных округах</h2>
    <!--    {{ getItemForums.regional_forums}}-->
    <!--    закоментил через v-if-->
    <section class="stepper">
      <div class="stepper__header">
        <div></div>
        <div class="stepper__step__content">
          <div></div>
          <div>Округ</div>
          <div>Дата</div>
          <div>Город</div>
          <div></div>
        </div>
      </div>
      <div v-for="(item, index) in filteredForumsList" :key="index" class="stepper__step" @click="checkedForum=index">
        <span :class="{'active' : index === checkedForum, 'arrow' : item.application}"></span>
        <router-link :to="item.link" class="stepper__step__content step__link" v-if="item.register_open || (item.date_start && (new Date(item.date_start).getTime() < new Date().getTime()))">
          <div class="stepper__step__content-image"><img :src="item.img"
                                                         :alt="item.title"></div>
          <div class="stepper__step__content-title">{{ item.district }}</div>
          <div class="stepper__step__content-date">{{ item.date || "Уточняется" }} <span class="step-mobile">{{ item.city }}</span>
          </div>
          <div class="stepper__step__content-city">{{ item.city }}</div>
          <div class="stepper__step__content-button">
            <input v-show="item.register_open"
                   type="submit"
                   value="Регистрация"
            >
          </div>
        </router-link>
        <div class="stepper__step__content" v-else>
          <div class="stepper__step__content-image"><img :src="item.img"
                                                         :alt="item.title"></div>
          <div class="stepper__step__content-title">{{ item.district }}</div>
          <div class="stepper__step__content-date">{{  item.date || "Уточняется"  }} <span class="step-mobile">{{ item.city }}</span>
          </div>
          <div class="stepper__step__content-city">{{ item.city }}</div>
          <div class="stepper__step__content-button">

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomSelect from '@/components/forms/CustomSelect'

export default {
  name: 'ForumStepper',
  components: {
    CustomSelect,
    'tricolor-line': TricolorLine
  },
  data () {
    return {
      checkedForum: 0,
      forumsList: [],
      thisYear: 'Выберите год',
      forumsFilterYear: null,
      dateYear: [
        {
          title: '2021 г.',
          value: '2021-01-01'
        },
        {
          title: '2022 г.',
          value: '2022-01-01'
        }
      ]
    }
  },
  computed: {
    getMainForums () {
      return this.$store.getters.GET_MAIN_FORUMS
    },

    getForumsYears () {
      const forums = JSON.parse(JSON.stringify(this.getMainForums)).sort((a, b) => b.year - a.year)
      return forums.map((forum, index) => {
        return {
          title: forum.year,
          value: index
        }
      })
    },

    getItemForums () {
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    },

    filteredForumsList () {
      if (this.forumsFilterYear) {
        return this.forumsList.filter((forum) => {
          const currentYear = new Date(this.forumsFilterYear).getFullYear()
          return new Date(forum.date_start).getFullYear() === currentYear || new Date(forum.date_end).getFullYear() === currentYear
        })
      }
      return this.forumsList
    }
  },
  watch: {
    getItemForums () {
      this.setForumsData()
      this.thisYear = this.getItemForums.year
    }
  },
  methods: {
    setForumsYear (year) {
      const forums = JSON.parse(JSON.stringify(this.getMainForums)).sort((a, b) => b.year - a.year)
      this.$store.commit('SET_ITEM_MAIN_FORUM', forums[year.value])
      // this.forumsFilterYear = year.value
    },

    async setForumsData () {
      const regionalForums = await this.$store.getters.GET_MAIN_ITEM_FORUM
      this.forumsList.length = 0
      regionalForums.regional_forums.forEach((item, i) => {
        this.forumsList.push({
          id: item.id,
          img: item.image,
          title: item.title,
          district: item.district,
          date: item.date_start !== null ? this.formattingDate(item.date_start, item.date_end) : null,
          date_start: item.date_start,
          date_end: item.date_end,
          city: item.location,
          application: this.isActiveForum(item.date_start, item.date_end, i),
          register_open: item.register_open,
          link: `/forum/${item.id}`
        })
      })
    },
    formattingDate (start, end) {
      const dateStart = new Date(start)
      const dateEnd = new Date(end)
      const locale = 'ru-ru'
      const format = {
        day: 'numeric',
        month: 'long'
      }
      if (dateStart.getMonth() === dateEnd.getMonth()) {
        return `${dateStart.getDate()}-${dateEnd.toLocaleString(locale, format)}`
      } else {
        return `${dateStart.toLocaleString(locale, format)} - ${dateEnd.toLocaleString(locale, format)}`
      }
    },
    isActiveForum (dateStart, dateEnd, i) {
      const dateStop = new Date(dateEnd)
      const dateStarts = new Date(dateStart)
      const currentDate = new Date()
      if (dateStop > currentDate && currentDate > dateStarts) {
        this.checkedForum = i
      }
      return currentDate < dateStop && currentDate > dateStarts
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-mobile {
    display: none;
  }

  .margin-three-color {
    margin: 1.8125rem 0 2rem 0;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: .8125rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
  }

  .stepper__step, .stepper__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    > span {
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 28px;
        height: 28px;
        margin-top: -.8rem;
        margin-right: 20px;
        background: url('../../assets/icons/circle-circle.svg') no-repeat center center;
        z-index: 2;
      }
      &::after {
        content: "";
        margin-top: 0rem;
        position: absolute;
        z-index: 1;
        height: 6.6rem;
        border-left: 2px #D6E6F5 solid;
        margin-left: 13px;
      }

      &.arrow {
        &::before {
          height: 45px;
          background: url('../../assets/icons/arrow-step.svg') center center;
        }
      }

      &.active {
        &::before {
          background: url('../../assets/icons/circle-active.svg') no-repeat center center;
        }
      }
    }

    &:last-child > span {
      &::before {
        width: 8px;
        height: 8px;
        /*background: #D6E6F5;*/
        border-radius: 4px;
        margin: 0 10px;
      }

      .active {
        background: #246CB7;
      }

      &:after {
        height: 0;
      }
    }

    > a, div {
      width: calc(100% - 50px);
    }

    .step__link:hover {
      box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
      cursor: pointer;
    }

    .stepper__step__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-around;
      align-items: center;
      line-height: 1.5;
      color: #1F3245;
      border: 1px solid #E5E8EE;
      box-sizing: border-box;
      border-radius: 8px;
      flex: none;
      order: 0;
      flex-grow: 0;

      > div {
        width: 20%;
        object-fit: cover;
        overflow: hidden;
      }

      > div:first-child {
        width: 17%;
      }

      > div:last-child {
        width: 21.5%;
      }

      &-image {
        height: 4.5rem;
        padding-right: 1.875rem;

        > img {
          width: 6.6rem;
          border-radius: 6px;
        }
      }

      &-title {
        font-size: .875rem;
        line-height: 1.57;
        font-weight: 600;
      }

      &-content-button {
      }
    }
  }

  .stepper__header {
    margin: 1.25rem 0 .75rem 0;
    border: 0;

    .stepper__step__content {
      border: 0;
      cursor: default;

      &:hover {
        box-shadow: none;
      }
    }
  }

  input {
    margin: 0;
    margin-bottom: 1.375rem;
  }

  input[type=submit] {
    margin: 1rem auto;
    width: 10rem;
    height: 2.1875rem;
    background: #246CB7;
    border-radius: .1875rem;
    color: #fff;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.43;
    cursor: pointer;

    &:hover {
      background: #246cb780;
    }
  }

  @media screen and (max-width: 768px) {
    .stepper__step, .stepper__header {

      .stepper__step__content {
        div {
          width: 18%;
        }

        > div:last-child {
          width: 27.5%;
        }

        &-image {
          width: 18%;
          padding-right: 1.5rem;

          > img {
            max-width: 120%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    section:before {
      margin-top: 5.3125rem;
      height: 59.8rem;
    }
    .step-mobile {
      display: inline-block;
      margin-left: 2rem;
    }
    .stepper__header {
      display: none !important;
    }

    .stepper__step__content {
      flex-direction: column;
      padding-bottom: 1rem;

      h2 {
        margin-bottom: 2.75rem;
      }

      > div {
        width: 100% !important;
        font-size: 1rem !important;
        margin: 1rem 0 0 2.8rem;
      }

      > div:last-child {
        display: contents;
      }

      .stepper__step__content-image {
        margin: 0;
        display: block;
        padding: 0;
        border-radius: 8px;
        height: 5rem;

        > img {
          margin-top: -33%;
          width: 100%;
        }
      }

      .stepper__step__content-city {
        display: none;
      }
    }
  }
</style>
