<template>
  <div>
    <h2>
      <slot>O форуме</slot>
    </h2>
    <section class="stepper">
      <article>
<!--        <header>-->
<!--          <p>К участию в форуме приглашаются гражданские активисты, некоммерческие организации, социальные-->
<!--            предприниматели, представители бизнеса, муниципальной и региональной власти и журналисты.</p>-->
<!--        </header>-->
<!--        <figure>-->
<!--          &lt;!&ndash;  стрим видео &ndash;&gt;-->
<!--          <div class="stream-video">-->
<!--            &lt;!&ndash; <p class="stream-video__iframe" v-html='getStream.liveStream'></p> &ndash;&gt;-->
<!--          </div>-->
<!--        </figure>-->
<!--        <p v-html='content' v-trim="20"></p>-->
        <div v-html='content' v-trim="28"></div>
<!--        {{getItemForums.about_text}}-->
      </article>
      <!-- <forum-documents :documentsList="getFile"/> -->
    </section>
  </div>
</template>

<script>
// import ForumDocuments from '@/components/activitiesSurveys/ForumDocuments'
import formatSizeFile from '@/mixins/formatSizeFile'
export default {
  name: 'ForumAbout',
  mixins: [formatSizeFile],
  components: {
    // ForumDocuments
  },
  data () {
    return {
      getTempIdNews: 0,
      content: '',
      getFile: [
        {
          name: 'Доклад о состоянии гражданского общества',
          url: '/',
          size: '125 Кб',
          type: 'pdf'
        }
      ]
    }
  },
  created () {
    // this.$store.dispatch('getMainItemForum', 1)
  },
  computed: {
    getItemForums () {
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    }
  },
  watch: {
    getItemForums () {
      const dataFromServer = this.$store.getters.GET_MAIN_ITEM_FORUM
      this.content = dataFromServer.about_text
      this.getFile = [{
        name: dataFromServer.about_file_title,
        url: dataFromServer.about_file_url,
        size: this.formatBytes(dataFromServer.about_file_size),
        type: dataFromServer.about_file_type
      }]
    }
  }
}
</script>

<style lang="scss" scoped>

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
    margin-bottom: 1.25rem;
  }

  ::v-deep p {
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    line-height: 157%;
    color: rgba(23, 23, 23, 0.8);
  }

  .stream-video {
    margin-top: 1.62rem;
    position: relative;
    margin-bottom: 2.5rem;

    &__iframe {
      width: 100%;
      height: 27.5rem;
      display: flex;

      ::v-deep iframe {
        border-radius: 0.5rem;
      }
    }
  }
  ::v-deep img {
    margin-right: 1.25rem;
  }

  @media screen and (max-width: 768px) {
    .stream-video {
      &__iframe {
        width: 100%;
        height: 21.81rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .stream-video {
      &__iframe {
        width: 100%;
        height: 12.5rem;
      }
    }
  }

</style>
