<template>
  <div>
    <h2>
      <slot>Исследование</slot>
    </h2>
    <section class="stepper">
      <article>
        <!--        <header>-->
        <!--          <p>К участию в форуме приглашаются гражданские активисты, некоммерческие организации, социальные-->
        <!--            предприниматели, представители бизнеса, муниципальной и региональной власти и журналисты.</p>-->
        <!--        </header>-->
        <!--        <p v-html='content' v-trim="8"></p>-->
        <p v-html='content' v-trim="18"></p>
      </article>
      <forum-documents :documentsList="getFile"/>
    </section>
  </div>
</template>

<script>
import ForumDocuments from '@/components/activitiesSurveys/ForumDocuments'

export default {
  name: 'ForumResearch',
  components: {
    ForumDocuments
  },
  data () {
    return {
      getTempIdNews: 0,
      content: '',
      getFile: [
        {
          name: 'Каталог исследований',
          url: '/',
          size: '',
          type: 'link'
        }
      ]
    }
  },
  created () {
    // this.$store.dispatch('getMainItemForum', 1)
  },
  computed: {
    getItemForums () {
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    }
  },
  watch: {
    getItemForums () {
      const dataFromServer = this.$store.getters.GET_MAIN_ITEM_FORUM
      this.content = dataFromServer.study_text
      this.getFile = [
        {
          name: 'Каталог исследований',
          url: dataFromServer.study_link,
          size: '',
          type: 'link'
        }
      ]
      // this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
    margin: 3.125rem 0 1.25rem 0;
  }

  ::v-deep p {
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    line-height: 157%;
    color: rgba(23, 23, 23, 0.8);
  }
</style>
